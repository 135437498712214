import { Box, SystemProps, Flex, css } from '@storyofams/react-ui';
import { Image } from '@storyofams/storyblok-toolkit';

import { Heading, Slider, Button, Text, FixedRatio, Container } from '~components';
import { getLinkProps } from '~lib';
import { DynamicSectionContainer } from '..';
import { storyblokEditable } from '@storyblok/react';
import Grid from '~components/common/Grid';

type UspsProps = {
  content: any;
  first?: boolean;
} & SystemProps;

export const Usps = ({ content, first, ...props }: UspsProps) => {
  const { title_bold, title, usps, text, text_color, central_text } = content;
  return (
    <DynamicSectionContainer {...props} bg={content?.bg} px={0} {...storyblokEditable(content)}>
      <Container py={[40, 100]}>
        <Grid gridRowGap={"16px"}>
          <Heading
            variant="h2"
            as={'h2'}
            lineHeight="140%"
            maxWidth={640}
            gridColumn={["span 5", "span 7", "span 7"]}
            padding={0}
            color={text_color}
          >
            <Box as="span" fontWeight="bold">
              {title_bold}{' '}
            </Box>

            <Box as="span" fontWeight="normal">
              {title}
            </Box>
          </Heading>

          {text && (
            <Text
              variant="s"
              fontSize={[1.5, 2, 2.5]}
              color={text_color}
              gridColumn={["span 6", "span 7", "span 8"]}
            >
              {text}
            </Text>
          )}

          <Box 
            gridColumn={"1/-1"}
            p={0} 
            mx={["-12px", "-40px", "-80px", "-130px"]}
            css={css({
              '& .swiper-wrapper > .swiper-slide': {
                width: "60% !important",
                '@media screen and (min-width: 480px)': {
                  width: "60% !important",
                },
                '@media screen and (min-width: 768px)': {
                  width: "40% !important",
                },
                '@media screen and (min-width: 1024px)': {
                  width: "30% !important",
                },
              }
            })}
          >
            <Slider
              spaceBetween={32}
              slidesPerView={1.5}
              sliderType={'usps'}
              breakpoints={{
                1024: { slidesPerView: 3 },
              }}
            >
              {usps?.map((item) => (
                <Box
                  display="flex"
                  flexDirection="column"
                >
                  <FixedRatio
                    flex={1}
                    ratio={[
                        [200, 120],
                      [200, 120],
                      [368, 264],
                    ]}
                    overflow="hidden"
                  >
                    <Image
                      width="100%"
                      height="100%"
                      alt={item?.image?.alt}
                      src={item?.image?.filename}
                      fluid={500}
                      fit="cover"
                    />
                  </FixedRatio>

                  <Flex
                    flex={1}
                    flexDirection="column"
                    pt={[2, 2, 3]}
                    alignItems={central_text ? 'center' : 'flex-start'}
                  >
                    <Heading
                      variant="h4"
                      as={'h3'}
                      mb={[1, 1.25, 1.5, 2]}
                      fontWeight="bold"
                      fontSize={[1.75, 1.75, '23px']}
                      width={"fit-content"}
                      color={text_color}
                    >
                      {item.title}
                    </Heading>

                    {item.description &&
                      <Text variant="s" color={text_color} fontSize={[1.5, 1.5, 2]} mb={[1, 1.5, 2, 3]}>
                        {item.description}
                      </Text>
                    }

                    {!!item.link_label && !!item.link && (
                      <Button
                        variant="link"
                        to={getLinkProps(item.link)}
                        fontSize={[1.75]}
                        ml={[0]}
                      >
                        <Box as="span" display={['none', 'none', 'flex']}>
                          {item.link_label}
                        </Box>
                      </Button>
                    )}
                  </Flex>
                </Box>
              ))}
            </Slider>
          </Box>
        </Grid>
      </Container>
    </DynamicSectionContainer>
  );
};
